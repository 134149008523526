{
   

  "no_data": {
    "page_notfound": "عذراً ،، هذه الصفحة غير متوفرة",
    "page_notfound_message": " المعذرة، هذه الصفحة غير موجودة، قد تكون حذفت، أو نقلت لمكان جديد.",
    "backhome": "العودة للرئيسية"
   
  },
  "forms": {
    "name":{
      "label":"الاسم",
      "placeholder":""
    },
    "description":{
      "label":  "الرسالة",
      "placeholder":"... ما الذي تريد اخبارنا به"
    },
    
    "email": {
      "label": "البريد الإلكتروني",
      "placeholder":"يرجى إدخال البريد الإلكتروني للتواصل"
    }
    
   
    
  },

  "modal": {
   "success_title":"تم الإرسال",
   "success_subtitle":"تم إرسال طلبك بنجاح , سيقوم فريق خدمة العملاء بالتواصل معك في اقرب وقت ممكن ^_^ نسعد بخدمتك"


  }
  
}
