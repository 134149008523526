{
  "nav": {
    "home": "الرئيسية",
    "about": "عن سفر ساس",
    "manage_customer_requests": "إدارة طلبات العملاء",
    "integration_with_global_systems": "الربط مع الأنظمة العالمية",
    "contact_us": "تواصل معنا",
    "trial_version": "الإصدار التجريبي"
  },
  "header": {
    "release_advertisment": "استغل عروض إطلاق المنتج وأحصل على تخفيض 50% 🥳",
    "logo": "سفـر ساس"
  },
  "common": {
    "contact_for_trial": "تواصل معنا للتجربة",
    "soon": "قريباً"
  },
  "hero_section": {
    "title": "سفر ساس الحل التقني الأمثل لإدارة خطط السفر وعروض الأسعار",
    "subtitle": "أنشئ عروض سعر إبداعية و أرسلها لعملائك لاستعراضها بشكل مبتكر، وقم بإدارة طلبات العملاء و البيانات المالية بشكل دقيق، وتمتع بحلول تقنية فعّالة تمكّنك من الارتقاء بمشروعك السياحي"
  },
  "about": {
    "about_us": "من هو سفر ساس؟",
    "title": "حقل تقني مبتكر لوكالات السفر و السياحية",
    "subtitle": "نظام السفر الأفضل لوكالات السفر والسياحة لإدارة الرحلات السياحية بكل مراحلها، بدءًا من إعداد خطة السفر و إرسال عروض الأسعار للعملاء ثم تنظيم حجوزات الطيران والفنادق إلى إدارة العملاء والتسويق مع تخصيص كامل و الاهتمام لكل التفاصيل في رحلة العميل الخاص بوكالة السفر و السياحة.",
    "services": [
      {
        "title": "الرحلات اليومية",
        "description": "تقديم خطط يومية مفصلة للأنشطة السياحية، مما يمكن العملاء من الاستمتاع بتجربة غنية ومتكاملة، دون الحاجة للقلق حول التنظيم والترتيب"
      },
      {
        "title": "مشتملات الباقات",
        "description": "تصميم باقات سياحية تشمل كافة التفاصيل اللازمة لراحة العملاء، من وجبات الطعام إلى خدمات الضيافة، مع القدرة على التخصيص الكامل"
      },
      {
        "title": "الفنادق",
        "description": "توفير حلول شاملة لحجوزات الطيران، مع إمكانية التخصيص للرحلة، لتوفير مجموعة متنوعة من شركات الطيران تناسب كل الميزانيات"
      },
      {
        "title": "الطيران",
        "description": "توفير حلول شاملة لحجوزات الطيران، مع إمكانية التخصيص للرحلة، لتوفير مجموعة متنوعة من شركات الطيران تناسب كل الميزانيات"
      }
    ]
  },
  "features": {
    "customer_Service_feature": "موقع إلكتروني لعرض باقاتك ووجهاتك السياحية",
    "customer_Service_feature_description": "قريباً، سيتيح لك سفر ساس إنشاء موقع إلكتروني خاص بشركتك، حيث يمكنك عرض باقاتك السياحية بكل سهولة و إتاحة الشراء عبر الموقع الإلكتروني.",
    "customer_prices_feature": "إنشاء وأرسال عروض السعر للعملاء",
    "customer_prices_feature_description": "مع سفر ساس، يمكنك إنشاء عروض سعر شاملة ومفصلة، تتضمن جميع البيانات التي يحتاج المستخدم إلى معرفتها، بما في ذلك الأسعار، وذلك من خلال واجهة سهلة الاستخدام وتجربة مريحة.",
    "customer_landing_feature": "موقع إلكتروني لعرض باقاتك ووجهاتك السياحية",
    "customer_landing_feature_description": "قريباً، سيتيح لك سفر ساس إنشاء موقع إلكتروني خاص بشركتك، حيث يمكنك عرض باقاتك السياحية بكل سهولة و إتاحة الشراء عبر الموقع الإلكتروني.",
    "global_services_integration": "الربط مع الأنظمة العالمية",
    "global_services_integration_description": "سيتيح لك سفر ساس الربط مع أشهر الأنظمة العالمية  للسفر والسياحة لإتمام و إنشاء حجوزات الفنادق و الطيران لعملائك. ومواكبة كل الرحلات الجديدة والتحديثات والعروض التي قد تطرأ."
  },
  "footer": {
    "title": "متخصصون بتقديم حلول تقنية متكاملة للسفر والسياحة",
    "copyright": "© جميع الحقوق محفوظة , شركة سفرفاي السياحية"
  }
}