import { createRouter, createWebHistory } from "vue-router";
import i18n from "../libs/i18n";
import NotFound from "../layouts/NotFound.vue";
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to) {
    if (to.hash) {
      return {
        // could also be
        // el: document.getElementById('main'),
        el: to.hash,
        // 10px above the element
        top: 100,
      }
  }
    return { top: 0 };
  },
  routes: [
    // {
    //   path: "/",
    //   redirect: `/${i18n.global.locale.value}/`,
    // },
    {
      path: "/",
      name: "home",
      component: () => import("@/views/index.vue"),
      
        
       
    },
    {
      path: "/:pathMatch(.*)*",
      meta: {
        layout: "full",
      },
      name: "not-found",
      component: () => import("@/views/index.vue"),
    },
  ],
});

// router.beforeEach((to, from, next) => {
//   const langs = ["ar"];
//   if (langs.includes(to.params.lang)) {
//     i18n.global.locale.value = to.params.lang;
//     const getHTMLTag = document.documentElement;
//     getHTMLTag.setAttribute("lang", to.params.lang);
   
//     getHTMLTag.setAttribute("dir", to.params.lang == "ar" ? "rtl" : "ltr");

//     return next();
//   } else {
//     if (to.name)
//       next({ name: to.name, params: { lang: i18n.global.locale.value } });
//     else next({ name: "home", params: { lang: i18n.global.locale.value } });
//   }
// });
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
// router.afterEach(() => {
//   // Remove initial loading
//   const appLoading = document.getElementById("loading-bg");
//   if (appLoading) {
//     // appLoading.style.display = 'none';
//   }
// });

router.resolve({
  name: "not-found",
  params: { pathMatch: ["not", "found"],  },
}).href; // '/not/found'
export default router;
