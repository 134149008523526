import logo from "../logo.svg?component";
import logoLight from "../logo-light.svg?component";
import instaIcon from "./insta.svg?component";
import xIcon from "./x.svg?component";
import facebookIcon from "./facebook.svg?component";
import tiktokIcon from "./tiktok.svg?component";
import youtubeIcon from "./youtube.svg?component";


import closeIcon from "./close.svg?component";
import airplaneIcon from "./airplane.svg?component";
import buildingIcon from "./building.svg?component";
import flashIcon from "./flash.svg?component";
import petIcon from "./pet.svg?component";
import rocketIcon from "./rocket.svg?component";
import tagIcon from "./tag.svg?component";
import whatsappIcon from "./whatsapp.svg?component";
import menuIcon from "./menu.svg?component";


import amadeus from "./global/amadeus.svg?component";
import booking from "./global/booking.svg?component";
import hotelbeds from "./global/hotelbeds.svg?component";
import sabre from "./global/sabre.svg?component";
import worldspan from "./global/worldspan.svg?component";

const svgComponents = (app) => {
  return app
    .component("logo", logo)
    .component("logo-light", logoLight)
    .component("facebook-icon", facebookIcon)
    .component("tiktok-icon", tiktokIcon)
    .component("insta-icon", instaIcon)
    .component("x-icon", xIcon)
    .component("youtube-icon", youtubeIcon)

    .component("close-icon", closeIcon)
    .component("building-icon", buildingIcon)
    .component("flash-icon", flashIcon)
    .component("pet-icon", petIcon)
    .component("rocket-icon", rocketIcon)
    .component("airplane-icon", airplaneIcon)
    .component("tag-icon", tagIcon)
    .component("whatsapp-icon", whatsappIcon)
    .component("menu-icon", menuIcon)


    .component("amadeus-logo", amadeus)
    .component("booking-logo", booking)
    .component("hotelbeds-logo", hotelbeds)
    .component("sabre-logo", sabre)
    .component("worldspan-logo", worldspan)

    
    
    ;
};
export default svgComponents;
