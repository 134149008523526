<script setup>
import { onMounted,ref } from 'vue';
import LandingHeader from './components/LandingHeader.vue'
import LandingFooter from './components/LandingFooter.vue'
import { homeAnimation } from "./libs/gsap";

const getHTMLTag = document.documentElement;
getHTMLTag.setAttribute("lang", 'ar');
getHTMLTag.setAttribute("dir", "rtl");
// const pageLoaded =ref(false)
// onMounted(() => {
// //   homeAnimation();
// // setTimeout(() => {
//   // homeAnimation();
// // }, 1000);
// window.onload = () => {
//   pageLoaded.value=true
//     homeAnimation();
//   };

// });
</script>
<template>
  <div  >
    <router-view />

  </div>
</template>
<style lang="scss"></style>
